import data from '../../fields';

export default {
  requiredFields: () => {
    return [
      'owner_is_known',
      'violation_type_id',
      'obj_type_id',
      'area_id',
      'address_district_id',
      'address_full',
      'audit_doc_number',
      'audit_doc_date',
      'file_audit_doc_files',
      'file_audit_doc_photos'
    ];
  },

  sections: (object, areaId) => {
    function getFiles(folder) {
      return object.files.filter(el => el.folder_code == folder);
    }

    return [
      {
        editable: true,
        title: 'ВЫЯВЛЕНИЕ ОБЪЕКТА',
        params: [
          {
            label: 'УЧЕТНЫЙ НОМЕР ОБЪЕКТА И ДАТА ПОСТАНОВКИ НА УЧЕТ',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled: true,
              placeholder: ''
            },
            property: 'obj_registration_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: '',
              disabled: true,
              name: 'obj_registration_date'
            },
            property: 'obj_registration_date'
          },

          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'ОБЩИЕ СВЕДЕНИЯ'
            }
          },
          {
            label: 'ТИП НАРУШЕНИЯ',
            component: 'uiSelect',
            fieldClass: 'mb-20',
            componentAttrs: {
              //modelValue: object.violation_type_id || '',
              items: data.violationTypes(),
              placeholder: 'Выберите тип нарушения'
            },
            property: 'violation_type_id'
          },

          {
            label: 'СОБСТВЕННИК ИЗВЕСТЕН',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              name: 'owner_is_known',
              items: data.boolRadio()
            },
            required: ['owner_info'],
            fieldClass: 'mb-20',
            property: 'owner_is_known'
          },
          {
            label: 'ИНФОРМАЦИЯ О СОБСТВЕННИКЕ (ЕСЛИ ИЗВЕСТЕН)',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите информацию',
              rows: 3
            },
            property: 'owner_info'
          },
          {
            label: 'ВИД РЕКЛАМНОЙ КОНСТРУКЦИИ',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.objectTypes(),
              placeholder: 'Выберите вид рекламной конструкции'
            },
            property: 'obj_type_id'
          },
          {
            label: 'КОЛИЧЕСТВО ИНФОРМАЦИОННЫХ ПОЛЕЙ',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите число'
            },
            property: 'panel_count'
          },
          {
            label: 'РАЗМЕР ИНФОРМАЦИОННОГО ПОЛЯ, м',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите число'
            },
            property: 'info_view_size'
          },
          {
            label: 'ПЛОЩАДЬ ИНФОРМАЦИОННОГО ПОЛЯ, кв.м.',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите число'
            },
            property: 'info_view_square'
          },
          {
            label: 'ГО / МР',
            component: 'uiSelect',
            fieldClass: 'mb-20',
            componentAttrs: {
              disabled: object.obj_registration_number,
              items: data.areas()
            },
            property: 'area_id',
            type: 'form_generator'
          },
          {
            label: 'РАЙОН ОБЪЕКТА',
            component: 'uiSelect',
            fieldClass: 'mb-20',
            componentAttrs: {
              items: data.districts(areaId),
              placeholder: 'Выберите район',
              disabled:
                !data.districts(areaId).length == 1 ||
                areaId == -1 ||
                object.obj_registration_number
            },
            property: 'address_district_id'
          },
          {
            label: 'АДРЕС ОБЪЕКТА',
            component: 'auto-address',
            fieldClass: 'mb-20',
            componentAttrs: {
              object: object,
              placeholder: '',
              areaId: areaId,
              disabled: object.obj_registration_number
            },
            property: 'address_full'
          },
          {
            label: 'ОПИСАНИЕ АДРЕСА',
            component: 'uiTextArea',
            fieldClass: 'mb-20',
            componentAttrs: {
              placeholder: 'Введите описание',
              //modelValue: object.address_info,
              rows: 3
            },
            property: 'address_info'
          },
          {
            component: 'mapPlace',
            fieldClass: 'mb-20',
            property: 'geo_polygon',
            componentAttrs: {
              center: object.geo_polygon
            }
          },
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title:
                'РЕКВИЗИТЫ АКТА ПРОВЕРКИ, УСТАНАВЛИВАЮЩЕГО ФАКТ ВЫЯВЛЕНИЯ РЕКЛАМНОЙ КОНСТРУКЦИИ'
            }
          },
          {
            label: 'НОМЕР И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled: object.guid,
              placeholder: 'Введите номер'
            },
            property: 'audit_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled: object.guid,
              name: 'property'
            },
            property: 'audit_doc_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_audit_doc_files',
            componentAttrs: {
              disabled_edit: object.guid,
              border_top: true,
              tooltipText: 'Акт проверки',
              proxyData: getFiles('audit_doc_files'),
              allowMultiple: 'true',
              uploadFolder: 'audit_doc_files'
            },
            type: 'form_generator'
          },
          {
            component: 'editPhotos',
            property: 'file_audit_doc_photos',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled_edit: object.guid,
              border_top: false,
              border_bottom: false,
              title: '',
              proxyData: getFiles('audit_doc_photos'),
              allowMultiple: 'true',
              uploadFolder: 'audit_doc_photos'
            }
          }
        ]
      },
      {
        editable: true,
        title: 'ОБЕСПЕЧИТЕЛЬНЫЕ МЕРЫ',
        params: [
          {
            label: 'НАЛОЖЕНЫ ЛИ ОБЕСПЕЧИТЕЛЬНЫЕ МЕРЫ?',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              name: 'warranty_measures_taken',
              items: data.boolRadio()
            },
            fieldClass: 'mb-10',
            required: ['warranty_measures_info'],
            property: 'warranty_measures_taken'
          },
          {
            label: 'КОММЕНТАРИЙ',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите комментарий',
              rows: 3
            },
            property: 'warranty_measures_info'
          }
        ]
      },
      {
        editable: true,
        title: 'ДОБРОВОЛЬНЫЙ ДЕМОНТАЖ',
        params: [
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title: 'СВЕДЕНИЯ О ПРЕДПИСАНИИ О ДЕМОНТАЖЕ РЕКЛАМНОЙ КОНСТРУКЦИИ'
            }
          },
          {
            label: 'НОМЕР И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            required: [
              'remove_doc_number',
              'remove_doc_date',
              'remove_doc_given_date',
              'remove_doc_given_to',
              'fix_date',
              'file_remove_order_files',
              'fix_doc_number',
              'fix_doc_date',
              'fix_doc_result',
              'file_remove_done_files',
              'file_remove_done_photos'
            ],
            property: 'remove_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_doc_date'
            },
            property: 'remove_doc_date'
          },
          {
            label: 'ДАТА ВЫДАЧИ',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_doc_given_date'
            },
            property: 'remove_doc_given_date'
          },
          {
            label: 'ЛИЦО, КОТОРОМУ ВЫДАНО ПРЕДПИСАНИЕ',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Укажите лицо'
            },
            property: 'remove_doc_given_to'
          },
          {
            label:
              'ДАТА ОКОНЧАНИЯ СРОКА ИСПОЛНЕНИЯ ОБЯЗАННОСТИ ПО ДЕМОНТАЖУ РЕКЛАМНОЙ КОНСТРУКЦИИ',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'fix_date'
            },
            property: 'fix_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_remove_order_files',
            componentAttrs: {
              border_top: true,
              tooltipText: 'сведения о предписании о демонтаже',
              proxyData: getFiles('remove_order_files'),
              allowMultiple: 'true',
              uploadFolder: 'remove_order_files'
            }
          },
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title:
                'СВЕДЕНИЯ ОБ ИСПОЛНЕНИИ ОБЯЗАННОСТИ ПО ДЕМОНТАЖУ РЕКЛАМНОЙ КОНСТРУКЦИИ'
            }
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'ml-10',
            componentAttrs: {
              title: 'АКТ ПРОВЕРКИ ИСПОЛНЕНИЯ'
            }
          },
          {
            label: 'НОМЕР И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10 ml-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            property: 'fix_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10 ml-10',
            componentAttrs: {
              name: 'fix_doc_date'
            },
            property: 'fix_doc_date'
          },

          {
            label: 'РЕЗУЛЬТАТЫ ПРОВЕРКИ ИСПОЛНЕНИЯ',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              name: 'remove_voluntary_is_done',
              items: [
                {
                  text: 'демонтирована',
                  value: true
                },
                {
                  text: 'не демонтирована',
                  value: false
                }
              ]
            },
            fieldClass: 'mb-10',
            property: 'fix_doc_result'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_remove_done_files',
            componentAttrs: {
              border_top: true,
              tooltipText: 'акт проверки исполнения требования',
              proxyData: getFiles('remove_done_files'),
              allowMultiple: 'true',
              uploadFolder: 'remove_done_files'
            }
          },
          {
            component: 'editPhotos',
            property: 'file_remove_done_photos',
            fieldClass: 'mb-10',
            componentAttrs: {
              border_top: true,
              title: '',
              proxyData: getFiles('remove_done_photos'),
              allowMultiple: 'true',
              uploadFolder: 'remove_done_photos'
            }
          }
        ]
      },
      {
        editable: true,
        title: 'ПРИНУДИТЕЛЬНЫЙ ДЕМОНТАЖ',
        params: [
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title:
                'РЕКВИЗИТЫ РАСПОРЯЖЕНИЯ ГЛАВЫ ТЕРРИТОРИАЛЬНОГО ОРГАНА О ПРИНУДИТЕЛЬНОМ ДЕМОНТАЖЕ ОБЪЕКТА'
            }
          },
          {
            label: 'НОМЕР СЭД И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            required: [
              'remove_force_doc_number',

              'remove_force_doc_date',
              'remove_force_bulletin_number',
              'remove_force_bulletin_date',
              'file_remove_force_files',
              'remove_plan_date',
              'remove_actual_date'
            ],
            property: 'remove_force_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_force_doc_date'
            },
            property: 'remove_force_doc_date'
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'СВЕДЕНИЯ ОБ ОФИЦИАЛЬНОМ ОПУБЛИКОВАНИИ'
            }
          },
          {
            label: '№ ОФИЦИАЛЬНОГО БЮЛЛЕТЕНЯ ОРГАНОВ МЕСТНОГО САМОУПРАВЛЕНИЯ',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            property: 'remove_force_bulletin_number'
          },
          {
            label: 'ДАТА',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_force_bulletin_date'
            },
            property: 'remove_force_bulletin_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_remove_force_files',
            componentAttrs: {
              border_top: true,
              tooltipText: 'распоряжение  о принудительном демонтаже',
              proxyData: getFiles('remove_force_files'),
              allowMultiple: 'true',
              uploadFolder: 'remove_force_files'
            }
          },
          {
            label: 'ДАТА ПЛАНИРУЕМОГО ДЕМОНТАЖА ОБЪЕКТА',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_plan_date'
            },
            property: 'remove_plan_date'
          },
          {
            label: 'ДАТА ФАКТИЧЕСКОГО ДЕМОНТАЖА ОБЪЕКТА',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_actual_date'
            },
            property: 'remove_actual_date'
          }
        ]
      },
      {
        title: 'ПОВТОРНЫЕ СВЕДЕНИЯ О ДОБРОВОЛЬНОМ ДЕМОНТАЖЕ ОБЪЕКТА',
        params: [
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'Повторные сведения о добровольном демонтаже объекта'
            }
          },
          {
            label: 'Добровольный демонтаж',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              //modelValue: object.sec_remove_voluntary_is_done || '',
              name: 'sec_remove_voluntary_is_done',
              items: data.boolRadio()
            },
            required: [
              'sec_remove_voluntary_doc_number',
              'sec_remove_voluntary_doc_date',
              'file_sec_remove_voluntary_files',
              'file_sec_remove_voluntary_photos'
            ],
            fieldClass: 'mb-10',
            property: 'sec_remove_voluntary_is_done'
          },
          {
            _if: object => {
              return object.sec_remove_voluntary_is_done;
            },
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title:
                'Акт проверки исполнения требования о добровольном демонтаже'
            }
          },
          {
            _if: object => {
              return object.sec_remove_voluntary_is_done;
            },
            label: 'Номер и дата Составления',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            required: [
              'remove_voluntary_doc_date',
              'remove_voluntary_is_done',
              'file_remove_voluntary_files',
              'file_remove_voluntary_photos'
            ],
            property: 'sec_remove_voluntary_doc_number'
          },
          {
            _if: object => {
              return object.sec_remove_voluntary_is_done;
            },
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'sec_remove_voluntary_doc_date'
              //modelValue: object.sec_remove_voluntary_doc_date || '',
            },
            property: 'sec_remove_voluntary_doc_date'
          }
        ]
      },
      {
        title: 'ПЕРЕМЕЩЕНИЕ И ХРАНЕНИЕ ОБЪЕКТА',
        params: [
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'СВЕДЕНИЯ ОБ АКТЕ О ДЕМОНТАЖЕ И ПЕРЕМЕЩЕНИИ ОБЪЕКТА'
            }
          },
          {
            label: 'НОМЕР И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            property: 'remove_act_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_act_date'
            },
            property: 'remove_act_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_remove_act_files',
            componentAttrs: {
              border_top: true,
              tooltipText: 'акт о демонтаже и перемещении объекта',
              proxyData: getFiles('remove_act_files'),
              allowMultiple: 'true',
              uploadFolder: 'remove_act_files'
            }
          },
          {
            component: 'editPhotos',
            property: 'file_remove_act_photos',
            fieldClass: 'mb-10',
            componentAttrs: {
              border_top: true,
              title:
                'фотографии объекта: акт о демонтаже и перемещении объекта',
              proxyData: getFiles('remove_act_photos'),
              allowMultiple: 'true',
              uploadFolder: 'remove_act_photos'
            }
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'СВЕДЕНИЯ О ХРАНЕНИИ ДЕМОНТИРОВАННОГО ОБЪЕКТА'
            }
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'МЕСТО ХРАНЕНИЯ ОБЪЕКТА'
            }
          },
          {
            label: 'АДРЕС ОБЪЕКТА, ОТВЕТСТВЕННЫЙ',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              //modelValue: object.keep_place_id || '',
              items: data.keepPlaces(),
              placeholder: 'Выберите место хранения'
            },
            property: 'keep_place_id'
          },
          {
            label: ' ОКОНЧАНИЕ СРОКА ХРАНЕНИЯ',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'keep_date'
              //modelValue: object.keep_date || '',
            },
            property: 'keep_date'
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title:
                'ИНФОРМАЦИЯ О ЗАХОРОНЕНИИ ЛИБО УТИЛИЗАЦИИ ДЕМОНТИРОВАННОГО ОБЪЕКТА'
            }
          },
          {
            label: 'НОМЕР ДОКУМЕНТА И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
              //modelValue: object.utilization_doc_number || '',
            },
            property: 'utilization_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'utilization_date'
              //modelValue: object.utilization_date || '',
            },
            property: 'utilization_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_utilization_files',
            componentAttrs: {
              border_top: true,
              tooltipText: 'сведения о хранении демонтированного объекта',
              proxyData: getFiles('utilization_files'),
              allowMultiple: 'true',
              uploadFolder: 'utilization_files'
            }
          }
        ]
      },
      {
        title: 'СВЕДЕНИЯ О ВОЗМЕЩЕНИИ ПОНЕСЕННЫХ РАСХОДОВ',
        params: [
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'Сведения о возмещении понесенных расходов'
            }
          },
          {
            label:
              'Сумма фактически понесенных расходов, связанных с осуществлением мероприятий по демонтажу, руб.',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: '0.00'
            },
            property: 'remove_cost_actual'
          },
          {
            label:
              ' СУММА ВОЗМЕЩЕННЫХ В БЮДЖЕТ ГОРОДА РАСХОДОВ, СВЯЗАННЫХ С ОСУЩЕСТВЛЕНИЕМ МЕРОПРИЯТИЙ ПО ДЕМОНТАЖУ, ПЕРЕМЕЩЕНИЮ, ХРАНЕНИЮ САМОВОЛЬНОГО(НЕЗАКОННОГО) ОБЪЕКТА, РУБ.',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: '0.00'
            },
            property: 'remove_cost_refunded'
          },
          {
            label:
              'ДАТА ВОЗМЕЩЕНИЯ В БЮДЖЕТ ГОРОДА РАСХОДОВ, СВЯЗАННЫХ С ОСУЩЕСТВЛЕНИЕМ МЕРОПРИЯТИЙ ПО ДЕМОНТАЖУ, ПЕРЕМЕЩЕНИЮ, ХРАНЕНИЮ САМОВОЛЬНОГО(НЕЗАКОННОГО) ОБЪЕКТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: '0.00'
            },
            property: 'remove_cost_refund_date'
          }
        ]
      }
    ];
  }
};
